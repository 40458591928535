.title {
    font-size: 4rem;
    font-family: "Assistant";
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.description {
    font-size: 1.4rem;
    font-family: "AssistantR";
    text-align: center;
    margin: 2% auto;
    direction: rtl;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

@media (max-width: 350px) {
    .title {
        font-size: 2.5rem;
    }
    .description {
        font-size: 1.05rem;
    }
}

@media (min-width: 351px) and (max-width: 450px) {
    .title {
        font-size: 2.8rem;
    }
    .description {
        font-size: 1.1rem;
    }
}

@media (min-width: 451px) and (max-width: 550px) {
    .title {
        font-size: 3rem;
    }
    .description {
        font-size: 1.2rem;
    }
}

@media (min-width: 551px) and (max-width: 650px) {
    .title {
        font-size: 3.2rem;
    }
    .description {
        font-size: 1.25rem;
    }
}

@media (min-width: 651px) and (max-width: 750px) {
    .title {
        font-size: 3.4rem;
    }
    .description {
        font-size: 1.3rem;
    }
}

@media (min-width: 751px) and (max-width: 850px) {
    .title {
        font-size: 3.6rem;
    }
    .description {
        font-size: 1.35rem;
    }
}

@media (min-width: 851px) and (max-width: 950px) {
    .title {
        font-size: 3.8rem;
    }
    .description {
        font-size: 1.37rem;
    }
}

@media (min-width: 951px) and (max-width: 1050px) {
    .title {
        font-size: 3.9rem;
    }
    .description {
        font-size: 1.38rem;
    }
}

@media (min-width: 1051px) and (max-width: 1150px) {
    .title {
        font-size: 3.95rem;
    }
    .description {
        font-size: 1.39rem;
    }
}

@media (min-width: 1151px) and (max-width: 1250px) {
    .title {
        font-size: 4rem;
    }
    .description {
        font-size: 1.4rem;
    }
}