.button {
    display: block;
    margin: 20px auto;
    padding: 16px 32px;
    font-size: 18px;
  direction: rtl;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 10px;
    font-family: "Assistant";
    cursor: pointer;
    transition: all 0.3s ease;
    background: linear-gradient(135deg, #b75a69, #d16a8c);
    color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  /* Rectangle 68 */




  .button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
  
  .button:active {
    transform: translateY(1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }