.title{
    font-size:4rem;
    font-family: "Assistant";
    text-align: center;
    margin:2% auto;
    direction:rtl;
    
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.description{
    font-size:1.4rem;
    font-family: "AssistantR";
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin:1% auto;
    direction: rtl;
    width:70%;
}
.descriptionP{
    font-size:1.4rem;
    font-family: "AssistantR";
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin:1% auto;
    direction: rtl;
    width:70%;
    padding-top: 80px;
}
.bold{
    font-size:1.4rem;
    font-family: "Assistant";
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin:1% auto;
    direction: rtl;
    width:70%;
}
.row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width:70%;
    margin:1% auto;
    justify-content: space-evenly;
}
.job{
    font-size:2.5rem;
    font-family: "Assistant";
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    text-align: center;
    margin:1% auto;
    direction: rtl;
    width:80%;
}

.center{
    display: flex;
    width:60%;
  margin: 2% auto;
}

.image{
    width:100%;
    object-fit: contain;

}
.title {
    font-size: 4rem;
    font-family: "Assistant";
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.description {
    font-size: 1.4rem;
    font-family: "AssistantR";
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 1% auto;
    direction: rtl;
    width: 70%;
}

.descriptionP {
    font-size: 1.4rem;
    font-family: "AssistantR";
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 1% auto;
    direction: rtl;
    width: 70%;
    padding-top: 80px;
}

.bold {
    font-size: 1.4rem;
    font-family: "Assistant";
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 1% auto;
    direction: rtl;
    width: 70%;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 70%;
    margin: 1% auto;
    justify-content: space-evenly;
}

.job {
    font-size: 2.5rem;
    font-family: "Assistant";
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    text-align: center;
    margin: 1% auto;
    direction: rtl;
    width: 80%;
}

.center {
    display: flex;
    width: 60%;
    margin: 2% auto;
}

.image {
    width: 100%;
    object-fit: contain;
}

@media only screen and (max-width: 350px) {
    .title {
        font-size: 2.5rem;
        margin:2% auto
    }
    
    .description, .descriptionP, .bold {
        font-size: 1.1rem;
        width: 90%;
        margin:2% auto
    }
    
    .bold {
        line-height: 1.5;
    }
    
    .job {
        font-size: 1.4rem;
        width: 100%;
    }
    
    .center {
        width: 95%;
        margin:2% auto
    }
    
    .row {
        width: 95%;
    }
}

@media only screen and (min-width: 350px) and (max-width: 450px) {
    .title {
        font-size: 2.5rem;
        margin: 0 auto;
    }
    
    .description, .descriptionP {
        width: 90%;
        font-size: 1.15rem;
    }
    
    .bold {
        font-size: 1.25rem;
        line-height: 1.4;
        width: 85%;
    }
    
    .job {
        font-size: 1.5rem;
        width: 100%;
    }
    
    .center {
        width: 90%;
    }
    
    .row {
        width: 95%;
    }
}

@media only screen and (min-width: 450px) and (max-width: 550px) {
    .title {
        font-size: 2.8rem;
        margin: 1% auto;
    }
    
    .description, .descriptionP {
        width: 85%;
        font-size: 1.2rem;
    }
    
    .bold {
        font-size: 1.3rem;
        line-height: 1.3;
        width: 80%;
    }
    
    .job {
        font-size: 1.7rem;
        width: 90%;
    }
    
    .center {
        width: 90%;
    }
    
    .row {
        width: 90%;
    }
}

@media only screen and (min-width: 550px) and (max-width: 650px) {
    .title {
        font-size: 3.5rem;
        margin: 2% auto;
    }
    
    .description, .descriptionP {
        width: 80%;
        font-size: 1.3rem;
    }
    
    .bold {
        font-size: 1.35rem;
        line-height: 1.4;
        width: 80%;
    }
    
    .job {
        font-size: 2rem;
        width: 85%;
    }
    
    .center {
        width: 80%;
    }
    
    .row {
        width: 85%;
    }
}

@media only screen and (min-width: 650px) and (max-width: 750px) {
    .title {
        font-size: 3.7rem;
        margin: 2% auto;
    }
    
    .description, .descriptionP {
        width: 65%;
        font-size: 1.35rem;
    }
    
    .bold {
        font-size: 1.35rem;
        line-height: 1.4;
        width: 75%;
    }
    
    .job {
        font-size: 2.2rem;
        width: 82%;
    }
    
    .center {
        width: 75%;
    }
    
    .row {
        width: 90%;
    }
}

@media only screen and (min-width: 750px) and (max-width: 850px) {
    .title {
        font-size: 3.8rem;
        margin: 2% auto;
    }
    
    .description, .descriptionP {
        width: 68%;
        font-size: 1.37rem;
    }
    
    .bold {
        font-size: 1.37rem;
        line-height: 1.4;
        width: 72%;
    }
    
    .job {
        font-size: 2.3rem;
        width: 81%;
    }
    
    .center {
        width: 65%;
    }
    
    .row {
        width: 70%;
    }
}

@media only screen and (min-width: 850px) and (max-width: 950px) {
    .title {
        font-size: 3.9rem;
        margin: 2% auto;
    }
    
    .description, .descriptionP {
        width: 69%;
        font-size: 1.38rem;
    }
    
    .bold {
        font-size: 1.4rem;
        line-height: 1.4;
        width: 70%;
    }
    
    .job {
        font-size: 2.4rem;
        width: 80%;
    }
    
    .center {
        width: 65%;
    }
    
    .row {
        width: 80%;
    }
}

@media only screen and (min-width: 950px) and (max-width: 1050px) {
    .title {
        font-size: 4rem;
        margin: 2% auto;
    }
    
    .description, .descriptionP {
        width: 70%;
        font-size: 1.4rem;
    }
    
    .bold {
        font-size: 1.4rem;
        line-height: 1.4;
        width: 65%;
    }
    
    .job {
        font-size: 2.45rem;
        width: 80%;
    }
    
    .center {
        width: 60%;
    }
    
    .row {
        width: 70%;
    }
}