.row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin:20px auto;
    width: 100%;
}

.title {
    font-size: 3.5rem;
    font-family: "Assistant";
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 350px) {
    .title {
        font-size: 2.2rem;
    }
    
    .row {
        width: 100%;
        
    }
}

@media only screen and (min-width: 350px) and (max-width: 450px) {
    .title {
        font-size: 2.6rem;
    }
    
    .row {
        width: 100%;
        margin:20px auto;
    }
}

@media only screen and (min-width: 450px) and (max-width: 550px) {
    .title {
        font-size: 2.9rem;
    }
    
    .row {
        width: 100%;
    }
}

@media only screen and (min-width: 550px) and (max-width: 650px) {
    .title {
        font-size: 3.1rem;
    }
    
    .row {
        width: 100%;
    }
}

@media only screen and (min-width: 650px) and (max-width: 750px) {
    .title {
        font-size: 3.2rem;
    }
    
    .row {
        width: 100%;
    }
}

@media only screen and (min-width: 750px) and (max-width: 850px) {
    .title {
        font-size: 3.3rem;
    }
    
    .row {
        width: 100%;
    }
}

@media only screen and (min-width: 850px) and (max-width: 950px) {
    .title {
        font-size: 3.4rem;
    }
    
    .row {
        width: 100%;
    }
}

@media only screen and (min-width: 950px) and (max-width: 1050px) {
    .title {
        font-size: 3.5rem;
    }
    
    .row {
        width: 100%;
    }
}